// post和get请求的封装
import axios from './request';
import BASE_URL from './base';
import { generateSign } from '@/utils/util'
const getCommonParams = (data) => {  // 请求参数合并
    // let loginInfo = wx.getStorageSync('userData') || '';
    let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
    // generateSign((cbData) => {

    return Object.assign({
        partner: 'yaojike',
        atime: loginInfo.timestamp, //cbData.timestamp
        user_id: loginInfo.memberId + '', //loginInfo.memberId + ''
        sign: loginInfo.sign, //cbData.sign
    }, data);
    // })
}
/**
 * 请求封装
 * @desc注册请求
 * @param {String} method [GET或POST]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
let configUrl = '';
export default function $http(method, url, params, type) {  //走本地接口 type: 1=药极客接口 2=o2o接口 3=春雨
    let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
    if (loginInfo) {
        generateSign()
    }
    if (type == 1) {
        configUrl = BASE_URL.devUrl;
        let opt = {
            // url: `${configUrl}${url}`,
            // url: `/dev_api${url}`, // 本地调试跨域打开
            url: process.env.NODE_ENV == 'development' ? `/dev_api${url}` : `${configUrl}${url}`, // 本地调试跨域打开
            method: method
        }
        let keyName = method.toLocaleUpperCase() === 'GET' ? 'params' : 'data';
        opt[keyName] = params;
        return axios(opt);
    } else if (type == 2) { // 走o2o接口
        configUrl = BASE_URL.o2oUrl;
        let opt = {
            // url: `${configUrl}${url}`,
            url: process.env.NODE_ENV == 'development' ? `/dev_api${url}` : `${configUrl}${url}`, // 本地调试跨域打开
            method: method
        }
        let keyName = method.toLocaleUpperCase() === 'GET' ? 'params' : 'data';
        opt[keyName] = params;
        return axios(opt);
    } else {  // 走第三方接口
        // // console.log(cb)
        configUrl = BASE_URL.thirdUrl;
        // console.log('configUrl', configUrl, process.env.NODE_ENV);
        params = getCommonParams(params)
        // console.log('params===>', params)
        let opt = {
            url: `/chunyu${url}`, // 线上需要跨域
            // url: `${configUrl}${url}`,  // 本地调试跨域打开
            // url: process.env.NODE_ENV == 'development' ? `/chunyu${url}` : `${configUrl}${url}`, // 本地调试跨域打开
            method: method
        }
        let keyName = method.toLocaleUpperCase() === 'GET' ? 'params' : 'data';
        opt[keyName] = params;
        return axios(opt);
    }
};
// export function $http_get(url, params) {
//     return axios({
//         url: `${configUrl}${url}`,
//         method: 'get',
//         params: params,
//     });
// };
