<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <tabbar v-if="$route.meta.showFooter" />
  </div>
</template>
<script>

import tabbar from './views/main';
export default {
  name: "App",
  components: {
    tabbar
  },
  data() {
    return {
      isShow: false,
      socket: null
    }
  },

  created() {
    // this.initSocket();

  },
  methods: {
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
