// import Vue from 'vue';
import axios from 'axios';
// import { Loading, Message } from 'element-ui';
import router from '../router/index.js';
import { Toast } from 'vant'
// import { delete } from 'vue/types/umd';

// let loading;


// 显示加载中遮罩层
// function startLoading() {
//     loading = Loading.service({
//         lock: true,
//         text: '加载中',
//         background: 'rgba(0, 0, 0, 0.7)'
//     });
// };

// // 关闭加载中遮罩层
// function endLoading() {
//     if (loading) loading.close();
// };

// 请求拦截
axios.interceptors.request.use(
    (confing) => {
        let userData = JSON.parse(localStorage.getItem('userData')) || ''
        // var isHideStartLoading = localStorage.getItem('isHideStartLoading');
        // if (isHideStartLoading == "Y") {
        // localStorage.removeItem('isHideStartLoading')
        // } else {
        // startLoading();
        // }
        // startLoading();
        if (userData) {
            confing.headers.Authorization = userData.token;
        }
        // confing.headers.clientType = 'operate';
        return confing;
    },
    (error) => {
        // endLoading();
        return Promise.reject(error);
    }


)

//响应拦截
axios.interceptors.response.use(
    (response) => {
        // endLoading();
        if (response.data.code == 401) {
            Toast(response.data.msg);
            //清楚token
            localStorage.removeItem('userData');
            //跳转到登录页面
            router.push('/home');
            return;
        }
        return response;
    },
    (error) => {
      // console.log(error);
        // endLoading();
        // debugger
        // Message.error(error.response.data);
        // this.$message.err(error);
        // 获取状态码
        const { status } = error.response;
        if (status === 401) {
            // Message.error('请重新登录');
            //清楚token
            localStorage.removeItem('token');
            //跳转到登录页面
            router.push('/login');
        }
        return Promise.reject(error);
    }
)
export default axios;
