<template>
  <div>
    <div v-if="is_login">
      <div class="shadow" @click="onClose"></div>
      <div class="login">
        <h4>请登录</h4>
        <van-field v-model="phone" label="手机号" type="number" placeholder="请输入手机号" />
        <template v-if="isCode">
          <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
            <template #button>
              <van-button size="small" @click="sendCode" :disabled="disabled" type="primary">{{codeMsg}}</van-button>
            </template>
          </van-field>
        </template>
        <div class="footer_btn">
          <button @click="onLogin">登录</button>
          <!-- <button>取消</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import axios from 'axios';
import qs from 'qs' // axios自带的工具不需要安装npm依赖
import { validPhone } from '@/utils/util';
import BASE_URL from '@/request/base';
export default {
  props: {
    is_login: {
      default: false,
      type: Boolean
    },
    canClose: {
      default: false,
      type: Boolean
    },
    isCode: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      seconds: 60,
      codeMsg: '发送验证码',
      timer: null,
      phone: '',
      sms: '',
      reqCode: '',
      disabled: false
    }
  },
  methods: {
    sendCode() {
      if (!validPhone(this.phone)) {
        Toast('请输入正确的手机号');
        return;
      }
      if (this.timer) return;
      let data = {
        phoneNumber: this.phone,
        templatePurpose: 2
      }
      this.timer = setInterval(() => {
        if (this.seconds == 0) {
          clearInterval(this.timer);
          this.codeMsg = '发送验证码';
          this.seconds = 60;
          this.timer = null;
          this.disabled = false;
        } else {
          this.disabled = true;
          this.seconds--;
          this.codeMsg = this.seconds + 's后重新发送';
        }
      }, 1000)
      axios.post('/sms/sms/dySendSms', qs.stringify(data)).then(res => { //sms/blendSendSms
        // console.log(res)
        if (res.data.code == 1) {
          this.reqCode = res.data.data;

        }
      })
    },
    onClose() {
      if (this.canClose) {
        this.$emit('onLoginClose')
      }
    },
    onLogin() {
      if (this.isCode) {
        if (!this.sms) {
          Toast('请输入验证码!');
          return;
        }
        if (this.sms != this.reqCode) {
          Toast('验证码输入不正确，请重新输入!');
          return;
        }
      } else {
        if (!validPhone(this.phone)) {
          Toast('请输入正确的手机号');
          return;
        }
      }
      let params = {
        phoneNum: this.phone,
        captcha: this.isCode ? this.sms : '',
        loginSourceName: '在线医生-h5',
        loginSourceType: 5
      }
      this.$http('post', '/server/member/login', params, 1).then(res => {
        // console.log(res);
        if (res.data.code == 200) {
          Toast(res.data.msg);
          localStorage.setItem('userData', JSON.stringify(res.data.data));
          this.onThirdLogin(res.data.data.memberPassword);
          this.$emit('onLoginSuccess')
          this.$socket('', data => {
            // console.log(data)
          })
        } else {
          Toast(res.data.msg);
        }
      })
    },
    // 第三方登录
    onThirdLogin(password) {
      let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
      // console.log('loginInfo', loginInfo)
      let params = {
        memberId: loginInfo.memberId
      }
      // console.log('this===>', this)
      let url = process.env.NODE_ENV == 'development' ? '/dev_api/server/sign/getSignInfo' : BASE_URL.devUrl + '/server/sign/getSignInfo';
      // axios.get(BASE_URL.devUrl + '/server/sign/getSignInfo', { params: params }).then(res => {
      axios.get(url, { params: params }).then(res => {
        // console.log('签名', res);
        if (res.data.code == 200) {
          loginInfo.timestamp = res.data.data.timestamp;
          loginInfo.sign = res.data.data.sign;
          localStorage.setItem('userData', JSON.stringify(loginInfo))
          let query = {
            password: password
          }
          this.$http('post', '/cooperation/server/login', query).then(res => {
            // console.log('登录', res);
          })
          // console.log(loginInfo);
        } else {
          Toast(res.data.msg);
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.shadow {
  background: #000;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}
.login {
  position: absolute;
  margin: 0 0.5rem;
  width: calc(100% - 1rem);
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  padding: 0.3rem 0;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  z-index: 99;
  h4 {
    margin-bottom: 0.3rem;
    font-size: 0.3rem;
  }
  /deep/ .van-button {
    background: #42cec8 !important;
    border: 1px solid #42cec8;
  }
  .footer_btn {
    margin-top: 0.3rem;
    button {
      width: 4rem;
      border: 0;
      color: #fff;
      height: 0.6rem;
      border-radius: 4px;
      font-size: 0.28rem;
    }
    button:nth-child(1) {
      background: #42cec8;
      //   margin-right: 0.4rem;
    }
    button:nth-child(2) {
      color: #ffa633;
    }
  }
}
</style>
