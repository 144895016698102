import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { title: '首页', showFooter: true }
  },
  {
    path: '/consult',
    component: () => import('../views/consult.vue'),
    meta: { title: '我的咨询', showFooter: true }
  },
  {
    path: '/mine',
    component: () => import('../views/mine.vue'),
    meta: { title: '我的', showFooter: true }
  },
  {
    path: '/detail',
    component: () => import('../views/detail.vue'),
    meta: { title: '详情' }
  },
  {
    path: '/find_doc',
    component: () => import('../views/find_doc.vue'),
    meta: { title: '找医生' }
  },
  {
    path: '/doctor_list',
    component: () => import('../views/doctor_list.vue'),
    meta: { title: '医生列表' }
  },
  {
    path: '/doctor_detail',
    component: () => import('../views/doctor_detail.vue'),
    meta: { title: '选择咨询类型' }
  },
  {
    path: '/tel_visits',
    component: () => import('../views/tel_visits.vue'),
    meta: { title: '电话问诊', keepAlive: false }
  },
  {
    path: '/fast_visits',
    component: () => import('../views/fast_visits.vue'),
    meta: { title: '快速问诊', keepAlive: false }
  },
  {
    path: '/system_doc_list',
    component: () => import('../views/system_doc_list.vue'),
    meta: { title: '推荐医生' }
  },
  {
    path: '/phone_order',
    component: () => import('../views/phone_order.vue'),
    meta: { title: '确认订单' }
  },
  {
    path: '/fast_order',
    component: () => import('../views/fast_order.vue'),
    meta: { title: '确认订单' }
  },
  {
    path: '/directional_order',
    component: () => import('../views/directional_order.vue'),
    meta: { title: '定向订单' }
  },
  {
    path: '/tel_wait',
    component: () => import('../views/tel_wait.vue'),
    meta: { title: '电话问诊' }
  },
  {
    path: '/chating',
    component: () => import('../views/chating.vue'),
    meta: { title: '问诊界面' }
  },
  {
    path: '/tel_chating',
    component: () => import('../views/tel_chating.vue'),
    meta: { title: '问诊界面' }
  },
  {
    path: '/choose_patients',
    component: () => import('../views/choose_patients.vue'),
    meta: { title: '选择问诊人' }
  },
  {
    path: '/add_visits_info',
    component: () => import('../views/add_visits_info.vue'),
    meta: { title: '添加问诊人信息' }
  },
  {
    path: '/my_visits',
    component: () => import('../views/my_visits.vue'),
    meta: { title: '我的问诊' }
  },
  {
    path: '/my_focus_on',
    component: () => import('../views/my_focus_on.vue'),
    meta: { title: '我的关注' }
  },
  {
    path: '/dev_chating',
    component: () => import('../views/dev_chating.vue'),
    meta: { title: '问诊界面' }
  },
  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import('../views/evaluation.vue'),
    meta: { title: '评价' }
  },
  {
    path: '/third_eval',
    name: 'third_eval',
    component: () => import('../views/third_eval.vue'),
    meta: { title: '评价' }
  },
  {
    path: '/order_detail',
    name: 'order_detail',
    component: () => import('../views/order_detail.vue'),
    meta: { title: '订单详情' }
  },
  {
    path: '/special_one_yuan',
    name: 'special_one_yuan',
    component: () => import('../views/special_one_yuan.vue'),
    meta: { title: '活动详情' }
  }
]
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
