import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $http from './request/http'
import $socket from './utils/socket';
import Vant from 'vant';
import 'vant/lib/index.css';
import './utils/flexible';
import bus from './utils/bus';
import BASE_URL from './request/base';
import voicePromptFun from './utils/voice';
// console.log(voicePromptFun)
Vue.prototype.voicePrompt = voicePromptFun  //语音提醒
Vue.prototype.$http = $http;
Vue.prototype.$socket = $socket;
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.use(Vant)
router.beforeEach((to, from, next) => {
  // next();
  let token = localStorage.getItem('token');
  document.title = to.meta.title;
  // const role = localStorage.getItem('ms_username');
  // console.log('role', token, from)
  next();
  // if (!token && !to.path !== '/login' && to.path !== '/register' && to.path !== '/forget-password1' && to.path !== '/forget-password2') {
  //   next('/login');
  // } else {
  //   next();
  // }

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
