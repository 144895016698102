//域名统一管理

const BASEURL = {
    // devUrl: 'http://zhanglei:9400',
    // devUrl: 'http://192.168.0.56:9400',
    // devUrl: 'http://192.168.0.150:9400',
    devUrl: 'https://test-onlinedoctor-api.yaojixin.com', // 测试地址
    testUrl: 'https://test-onlinedoctor-api.yaojixin.com', // 测试地址
    productUrl: 'https://onlinedoctor-api.yaojixin.com' // 生产地址
}
const third_url = {
    // devUrl: 'https://www.chunyuyisheng.com',
    devUrl: 'https://test.chunyutianxia.com',
    proUrl: 'https://www.chunyuyisheng.com'
}
const o2o_url = {
    devUrl: 'https://pandian.yaojimai.com/',
    testUrl: 'https://pandian.yaojimai.com/',
    proUrl: 'https://pandian.yaojimai.com/'
}
const socket_url = {
    devUrl: 'wss://test-websocket-server.yaojixin.com/',
    // devUrl: 'ws://zhanglei:9010',
    // devUrl: 'ws://192.168.0.50:9010',
    // testUrl: 'wss://test-onlinedoctor-api.yaojixin.com/websocket',
    testUrl: 'wss://test-websocket-server.yaojixin.com/',

    productUrl: 'wss://websocket-server.yaojixin.com/',
}
const wx_url = {
    devUrl: 'http://192.168.0.86:80', // 测试地址
    testUrl: 'https://test-onlinedoctor-h5.yaojixin.com', // 测试地址
    productUrl: 'https://onlinedoctor-h5.yaojixin.com' // 生产地址
}
let thirdUrl = '';
let devUrl = '';
let socketUrl = '';
let wxUrl = '';
let o2oUrl = '';
switch (process.env.NODE_ENV) {
    case 'development': // dev 本地
        thirdUrl = third_url.devUrl; //第三方域名
        devUrl = BASEURL.devUrl; //本地域名
        socketUrl = socket_url.devUrl;
        wxUrl = wx_url.devUrl;
        o2oUrl = o2o_url.devUrl;
        break
    case 'test':   // 生产测试
        thirdUrl = third_url.devUrl;
        devUrl = BASEURL.testUrl;
        socketUrl = socket_url.testUrl;
        wxUrl = wx_url.testUrl;
        o2oUrl = o2o_url.testUrl;
        break
    case 'production': // 生产
        thirdUrl = third_url.proUrl;
        devUrl = BASEURL.productUrl;
        socketUrl = socket_url.productUrl;
        wxUrl = wx_url.productUrl;
        o2oUrl = o2o_url.productUrl;
        break
}
// export default url;
// console.log(thirdUrl, devUrl);
// // console.log(process.env)
module.exports = {
    thirdUrl: thirdUrl,
    devUrl: devUrl,
    socketUrl: socketUrl,
    wxUrl: wxUrl,
    o2oUrl: o2oUrl
}
// export default BASEURL;
