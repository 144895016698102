<template>
  <div>
    <!-- <router-view /> -->
    <div class="nav">
      <div class="footer_nav">
        <div class="home item" @click="onPath('/home')">
          <img :src="[$route.path == '/home' ? require('../assets/img/selected_home.png') : require('../assets/img/shouye.png')]" alt="">
          <p :class="[$route.path == '/home' ? 'selected' : '']">首页</p>
        </div>
        <div class="consult item" @click="onCheckLoginState('onPath', '/consult')">
          <div class="middle" :class="[isShow == true ? 'icon_hd' : '']">
            <img :src="[$route.path == '/consult' ? require('../assets/img/selected_zixun.png') : require('../assets/img/consult.png')]" alt="">
            <p :class="[$route.path == '/consult' ? 'selected' : '']">我的咨询</p>
          </div>
        </div>
        <div class="mine item" @click="onCheckLoginState('onPath', '/mine')">
          <img :src="[$route.path == '/mine' ? require('../assets/img/selected_mine.png') : require('../assets/img/icon_mine.png')]" alt="">
          <p :class="[$route.path == '/mine' ? 'selected' : '']">我的</p>
        </div>
      </div>
    </div>
    <v-login :is_login="is_login" @onLoginSuccess="onLoginSuccess" :canClose="true" @onLoginClose="onLoginClose"/>
  </div>
</template>
<script>
import bus from '../utils/bus';
import vLogin from "@/components/login";
export default {
  data() {
    return {
      active: 0,
      isShow: false,
      is_login: false,
      loginState: null, // true=登录 false未登录
    };
  },
  components: {
    vLogin,
  },
  computed: {
  },
  created() {
    let loginData = JSON.parse(localStorage.getItem("userData")) || "";
    // console.log(loginData, "loginData");
    if (loginData) {
      this.is_login = false;
      this.loginState = true;
    } else {
      this.loginState = false;
    }
    bus.$on('showSpot', (data) => {
      // console.log('调用onBus', data);
      this.isShow = true;
    })
  },
  methods: {
    onCheckLoginState(fnName, params1, params2) {
      if (this.loginState) {
        this[fnName](params1, params2);
      } else {
        this.is_login = true;
      }
    },
    // 登录成功
    onLoginSuccess() {
      this.is_login = false;
      this.loginState = true;
    },
    onLoginClose() {
      this.is_login = false;
    },
    onPath(path) {
      // console.log(this.isShow)
      // console.log(this.$route)
      if (path == '/consult') {
        this.isShow = false;
      }
      this.$router.push(path);
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  // height: 1.86rem;
  // background: transparent;
}
.footer_nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../assets/img/nav_bg.png) no-repeat center;
  background-size: 100% 100%;
  height: 1.86rem;
  padding-top: 8px;
  // padding-bottom: 2px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  .item {
    flex: 1;
    -webkit-flex: 1;
    text-align: center;
    p {
      font-size: 0.24rem;
      color: #666666;
    }
    .selected {
      color: #ffb500;
    }
  }
  .home {
    img {
      margin-top: 0.6rem;
      width: 0.52rem;
      height: 0.5rem;
    }
  }
  .consult {
    img {
      margin-top: 0.3rem;
      width: 0.82rem;
      height: 0.82rem;
    }
  }
  .mine {
    img {
      margin-top: 0.6rem;
      width: 0.44rem;
      height: 0.54rem;
    }
  }
  .middle {
    position: relative;
  }
  .icon_hd::before {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: 1.63rem;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
  }
  // box-shadow: 0px -4px 20px 0px #bdbdbd;
  // .yuan {
  //   position: relative;
  //   top: -44px;
  // }
  // .middle {
  //   height: 80px;
  //   width: 80px;
  //   text-align: center;
  //   // line-height: 100px;
  //   border-radius: 50%;
  //   // background: #fff;
  //   position: absolute;
  //   top: -24px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   img {
  //     margin-bottom: 14px;
  //   }
  // }
  // p {
  //   font-size: 0.24rem;
  //   color: #111;
  // }
  // .home,
  // .mine {
  //   width: 2.5rem;
  //   // padding-top: 0.16rem;
  //   // padding-bottom: 6px;
  //   flex: 1;
  //   text-align: center;
  // }
  // .home img {
  //   width: 42px;
  //   height: 40px;
  //   // margin-bottom: 0.12rem;
  // }
  // .mine img {
  //   width: 34px;
  //   height: 44px;
  //   // margin-bottom: 0.12rem;
  // }
  // .consult {
  //   flex: 1;
  //   text-align: center;
  //   img {
  //     width: 60px;
  //     height: 60px;
  //   }
  // }
}
</style>
