import Vue from 'vue'
import Vuex from 'vuex'
import $http from '@/request/http'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message: '',
    arrImg: null,
    relation: '',
    interrogationId: null, //问诊人id
    visitsType: null,
    fastPrice: 5, //快速问诊价格
    floatRate: 0, //定向问诊价格浮动比例
    docDetail: {}, //医生详情
    docType: 2, //医生类型 2为春雨 1为药康夫
    priceInfo: {}, //电话时长价格
    orderIdArr: [], //未读消息订单id集合
    devPrice: 0,
    isActivity: false, // 是否是活动
    activityId: null  // 活动id
  },
  mutations: {
    getPrice(state, data) {
      data.forEach(item => {
        if (item.templateType == 1) {
          state.fastPrice = item.confPrice;
        } else {
          state.floatRate = item.floatRate;
        }
        // console.log('state====>', state)
      })
    },
    upDatePrice(state,price) {
      state.fastPrice = price
    },
    setActiveId(state,id) {
      state.isActivity = true
      state.activityId = id
    },
    resetActiveId(state) {
      state.isActivity = false
      state.activityId = null
    }
  },
  actions: {
    // 价格浮动
    setPrice(context) {
      let params = {
        templateType: 0
      }
      $http('get', '/server/priceConfTemplate/selectConfData', params, 1).then(res => {
        // console.log('价格', res)
        if (res.data.data) {
          context.commit('getPrice', res.data.data);
        }
      })
    }
  },
  modules: {
  }
})
