var websock = null;
var global_callback = null;
var timer = null;
import BASE_URL from '@/request/base';
import { Toast } from 'vant';
function initWebSocket() {
    //ws地址
    let url = BASE_URL.socketUrl
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
        return;
    }
    // websock = new WebSocket(`${url}?userId=${1111}&userType=1&sysType=2&msgContent=login`);
    websock = new WebSocket(`${url}?userId=${userData.memberId}&userType=1&sysType=2&msgContent=login`);
    websock.onopen = function () {
        websocketOpen();
    }
    websock.onmessage = function (e) {
        websocketonmessage(e);
    }
    websock.onclose = function (e) {
        websocketclose(e);
    }
    //连接发生错误的回调方法
    websock.onerror = function (e) {

        // initWebSocket();
        // console.log("WebSocket连接发生错误", e);
    }
}

// 实际调用的方法
function sendSock(agentData) {
    websocketsend(agentData)
    // // console.log('callback=====>', websock.readyState, agentData)
    /*    global_callback = callback;
       if (!websock) {
           initWebSocket();
           // return;
       }
       if (websock.readyState === 1) {
           //若是ws开启状态
           if (agentData) {
               websocketsend(agentData)
           }
       } else if (websock.readyState === 0) {
           // 若是 正在开启状态，则等待1s后重新调用
           setTimeout(function () {
               sendSock(agentData, callback);
           }, 1000);
       } else if (websock.readyState == 3) {
           initWebSocket();
       }
       else {
           // 若未开启 ，则等待1s后重新调用
           setTimeout(function () {
               sendSock(agentData, callback);
           }, 1000);
       } */
}

function onMessage(callback) {
    global_callback = callback;
}
//数据接收
function websocketonmessage(e) {
    global_callback(JSON.parse(e.data));
    // if (e.data) {
    //     if (e.data === 'websocket connection successfully !') {
    //     } else {
    //         global_callback(JSON.parse(e.data));
    //     }
    // }
}

//数据发送
function websocketsend(agentData) {
    // console.log('发送信息', agentData)
    if (websock.readyState == 3) { // 断开重连
      initWebSocket();
      Toast('连接已断开请，重新发送');
    } else {
      websock.send(JSON.stringify(agentData));
    }
}

//关闭
function websocketclose(e) {
  // // console.log("connection closed (" + e.code + ")");
  // console.log("连接已断开！");
}

function websocketOpen(e) {
    // console.log("连接成功", e);
}

initWebSocket();

// websock.close();
export default {
    sendSock,
    onMessage
};
